@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 217 90% 52%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 214.3 31.8% 91.4%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --success: 141 77% 39%;
    --success-foreground: 210 40% 98%;

    --warning: 43.26 96.41% 56.27%;
    --warning-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --sidebar-background: 0 0% 100%;
    --sidebar-foreground: 222.2 84% 4.9%;
    --sidebar-primary: 217 90% 52%;
    --sidebar-primary-foreground: 210 40% 98%;
    --sidebar-accent: 214.3 31.8% 91.4%;
    --sidebar-accent-foreground: 222.2 47.4% 11.2%;
    --sidebar-border: 214.3 31.8% 91.4%;
    --sidebar-ring: 222.2 84% 4.9%;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --background: 222.2 84% 4.9%;
      --foreground: 210 40% 98%;

      --card: 222.2 84% 4.9%;
      --card-foreground: 210 40% 98%;

      --popover: 222.2 84% 4.9%;
      --popover-foreground: 210 40% 98%;

      --primary: 217 90% 52%;
      --primary-foreground: 210 40% 98%;

      --secondary: 217.2 32.6% 17.5%;
      --secondary-foreground: 210 40% 98%;

      --muted: 217.2 32.6% 17.5%;
      --muted-foreground: 215 20.2% 65.1%;

      --accent: 215.29 25% 26.67%;
      --accent-foreground: 210 40% 98%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 40% 98%;

      --success: 141 77% 39%;
      --success-foreground: 210 40% 98%;

      --warning: 43.26 96.41% 56.27%;
      --warning-foreground: 210 40% 98%;

      --border: 215.29 25% 26.67%;
      --input: 215.29 25% 26.67%;
      --ring: 212.7 26.8% 83.9%;

      --sidebar-background: 222.2 84% 4.9%;
      --sidebar-foreground: 210 40% 98%;
      --sidebar-primary: 217 90% 52%;
      --sidebar-primary-foreground: 210 40% 98%;
      --sidebar-accent: 215.29 25% 26.67%;
      --sidebar-accent-foreground: 210 40% 98%;
      --sidebar-border: 215.29 25% 26.67%;
      --sidebar-ring: 212.7 26.8% 83.9%;
    }
  }
}
