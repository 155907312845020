@import "@dewrangle/ui/global.css";
@import "@fontsource-variable/inter";
@import "@fontsource-variable/fira-code";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-sans: "Inter Variable", sans-serif;
    --font-mono: "Fira Code Variable", monospace;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-secondary text-foreground;
  }
  h1 {
    @apply text-4xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  p {
    line-height: 1.75rem;
  }
}

.swagger-ui {
  background: #fafafa;
  min-height: 100vh;
}

.swagger-ui .info {
  margin: 0 !important;
  padding-top: 50px;
}
